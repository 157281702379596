import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { ContainerColumn, ContainerRow } from '../../styled';
const PortfolioAssetsContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.m,
}));
const PortfolioAssetsTooltips = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.xs * 6.25,
}));
const defineBorderRadius = (theme, edgeElement) => {
    if (edgeElement === 'left') {
        return `${theme.spacing_sizes.s}px 0 0 ${theme.spacing_sizes.s}px`;
    }
    if (edgeElement === 'right') {
        return `0 ${theme.spacing_sizes.s}px ${theme.spacing_sizes.s}px 0`;
    }
    return 'unset';
};
const defineEdgeElement = (index, areasCount) => {
    if (index === 0) {
        return 'left';
    }
    if (index === areasCount - 1) {
        return 'right';
    }
    return undefined;
};
const AssetTooltipBackground = styled.span(() => ({
    display: 'block',
    position: 'absolute',
    left: 0,
    height: '100%',
}));
const PortfolioAssetTooltip = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '0 9px',
    height: 24,
    cursor: 'default',
    color: props.isHovered ? '#fff' : props.theme.palette.text.secondary,
    zIndex: 1,
    [`${AssetTooltipBackground}`]: {
        width: props.isHovered ? 52 : 3,
        backgroundColor: props.color,
        borderRadius: 4,
        zIndex: props.isHovered ? -1 : 0,
    },
    '&:hover': {
        color: '#fff',
        [`${AssetTooltipBackground}`]: {
            width: 52,
            zIndex: -1,
        },
    },
}));
const ProgressBarContainer = styled.div(() => ({
    display: 'flex',
    width: '100%',
    height: 6,
}));
const ProgressBarItem = styled.div(props => ({
    backgroundColor: props.color,
    width: `${props.width}%`,
    height: 6,
    borderRadius: defineBorderRadius(props.theme, props.edgeElement),
}));
const AREAS_COLORS = {
    0: '#40C4AA',
    1: '#0091FF',
    2: '#8E4EC6',
    3: '#68DDFD',
    4: '#BE93E4',
    5: '#99D52A',
    6: '#6E56CF',
    7: '#05A2C2',
    8: '#8DA4EF',
    9: '#E93D82',
};
const PortfolioAssetsBar = ({ portfolios, hoveredElement = undefined, onAssetEnter = undefined, onAssetLeave = undefined, }) => {
    const { areas, count } = useMemo(() => {
        let countResult = 0;
        const areasResult = [];
        portfolios.forEach((portfolio, index) => {
            countResult += portfolio.totalAssetsAmount || 0;
            areasResult.push({
                count: portfolio.totalAssetsAmount || 0,
                id: portfolio.id,
                color: AREAS_COLORS[index] || '#fff',
            });
        });
        return { count: countResult, areas: areasResult };
    }, [portfolios]);
    const handleMouseEnter = (portfolioId) => {
        if (onAssetEnter) {
            onAssetEnter(portfolioId);
        }
    };
    const handleMouseLeave = (portfolioId) => {
        if (onAssetLeave) {
            onAssetLeave(portfolioId);
        }
    };
    return (_jsxs(PortfolioAssetsContainer, { children: [_jsx(ProgressBarContainer, { children: areas.map((area, index) => {
                    const edgeElement = defineEdgeElement(index, areas.length);
                    return (_jsx(ProgressBarItem, { color: area.color, width: (area.count / count) * 100, edgeElement: edgeElement }, area.id));
                }) }), (areas === null || areas === void 0 ? void 0 : areas.length) > 0 && (_jsx(PortfolioAssetsTooltips, { children: areas.map((area) => (_jsxs(PortfolioAssetTooltip, { color: area.color, onMouseEnter: () => handleMouseEnter(area.id), onMouseLeave: () => handleMouseLeave(area.id), isHovered: hoveredElement === area.id, children: [_jsx(AssetTooltipBackground, {}), `${Math.floor((area.count / count) * 100)}%`] }, area.id))) }))] }));
};
export default PortfolioAssetsBar;
